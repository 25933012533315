import GlobalFunctions from "./globalFunctions";
import Plans from "./Plans";

export default {
    methods: {
        getDayDiff(store_timestamp){
            try{
                let now = (new Date()).getTime();
                let prev = store_timestamp.toDate().getTime();
                return Math.floor(((now - prev)/(1000*60*60*24)))
            } catch (e){
                return 0
            }
        },
        getDate(date){
            return date.toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        },
        getTime(date){
            return date.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric', hour12: true })
        },
        getDatetime(date){
            return date.toLocaleString(undefined, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },
        getReadableDate(store_timestamp){
            if(store_timestamp){
                let _date = new Date(0);
                _date.setSeconds(store_timestamp.seconds);
                if(_date.getTime() === _date.getTime())
                    return _date.toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            }
            return ''
        },
        getReadableTime(store_timestamp){
            if(store_timestamp){
                let _date = new Date(0);
                _date.setSeconds(store_timestamp.seconds);
                if(_date.getTime() === _date.getTime())
                    return _date.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric', hour12: true })
            }
            return ''
        },
        getReadableDatetime(store_timestamp){
            if(store_timestamp){
                let _date = new Date(0);
                _date.setSeconds(store_timestamp.seconds);
                if(_date.getTime() === _date.getTime())
                    return _date.toLocaleString(undefined, {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
            }
            return ''
        },
        getReadableMonth(store_timestamp){
            if(store_timestamp){
                let _date = new Date(0);
                _date.setSeconds(store_timestamp.seconds);
                if(_date.getTime() === _date.getTime())
                    return (_date.getMonth()+1)
            }
            return ''
        },
        getReadableYear(store_timestamp){
            if(store_timestamp){
                let _date = new Date(0);
                _date.setSeconds(store_timestamp.seconds);
                if(_date.getTime() === _date.getTime())
                    return _date.getFullYear()
            }
            return ''
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        getFiatSymbol: (currency) => {
            // eslint-disable-next-line no-prototype-builtins
            if(GlobalFunctions.fiats.hasOwnProperty(currency)){
                return GlobalFunctions.fiats[currency].symbol_native;
            }
            return ''
        },
        getPercentageDiff(begin, end){
            let num_diff = (Number.parseFloat(begin) / Number.parseFloat(end)) || 0;
            num_diff = (num_diff*100);
            if(num_diff > 100) return 100;
            return Number.parseInt(num_diff)
        },

        getPlanPercentageDiff(deposit){
            let start_day = 0;
            let end_day = 0;
            try{
                if(deposit.status === 'IN-PROGRESS'){
                    const plan = Plans.all[deposit.plan][deposit.package];
                    start_day = this.getDayDiff(deposit.createdAt);
                    // Weekly earnings till lifespan
                    if(!plan.accumulation.status){
                        end_day = plan.lifeSpan*30.4167;
                    }else{
                        // Daily interest till days interval elapses
                        end_day = plan.accumulation.frequency;
                    }
                }

            }catch (e){
                console.log(e)
            }
            // console.log('start:', start_day);
            // console.log('end:', end_day)
            return this.getPercentageDiff(start_day, end_day)
        }
    }
}