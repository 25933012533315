<template>
    <div class="content-body">
        <div class="container-fluid">
            <!-- Add Project -->
            <div class="project-nav">
                <div class="card-action card-tabs  mr-auto">
                    <ul class="nav nav-tabs style-2">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link active" data-toggle="tab" aria-expanded="false">
                                Deposits <span class="badge badge-pill shadow-primary badge-primary">{{ deposits.length }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" data-toggle="tab" aria-expanded="false">
                                In Progress <span class="badge badge-pill badge-info shadow-info">{{inProgress.length}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" data-toggle="tab" aria-expanded="true">
                                Pending <span class="badge badge-pill badge-warning shadow-warning">{{pending.length}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" data-toggle="tab" aria-expanded="true">
                                Filled <span class="badge badge-pill badge-danger shadow-danger">{{filled.length}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--                <a href="javascript:void(0);" class="btn btn-primary">+New Project</a>-->
            </div>
            <div class="tab-content project-list-group" id="myTabContent">
                <div class="tab-pane fade active show" id="navpills-1">
                    <template v-if="deposits.length > 0">
                        <div class="card" v-for="(deposit, idx) in deposits" :key="'deposit_'+idx">
                            <div class="project-info">
                                <div class="col-xl-3 my-2 col-lg-4 col-sm-6">
                                    <p class="text-primary mb-1">#TE-{{deposit.id.substr(0, 10)}}</p>
                                    <h5 class="title font-w600 mb-2"><a href="javascript:void(0)" class="text-black">${{deposit.data.amountDeposited.toLocaleString()}}</a></h5>
                                    <div class="text-dark"><i class="fa fa-calendar-o mr-3" aria-hidden="true"></i>Created on {{getReadableDate(deposit.data.createdAt)}}</div>
                                </div>
                                <div class="col-xl-2 my-2 col-lg-4 col-sm-6">
                                    <div class="d-flex align-items-center">
                                        <div class="project-media">
                                            <span class="img-placeholder bgl-info text-info">{{deposit.data.plan.substr(0,2)}}</span>
                                        </div>
                                        <div class="ml-2">
                                            <span>Plan</span>
                                            <h5 class="mb-0 pt-1 font-w50 text-black">{{ deposit.data.plan }}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-2 my-2 col-lg-4 col-sm-6">
                                    <div class="d-flex align-items-center">
                                        <div class="project-media">
                                            <span class="img-placeholder bgl-success text-success">{{ deposit.data.package.substr(0,2) }}</span>
                                        </div>
                                        <div class="ml-2">
                                            <span>Package</span>
                                            <h5 class="mb-0 pt-1 font-w500 text-black">{{ deposit.data.package }}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 my-2 col-lg-6 col-sm-6">
                                    <div class="d-flex align-items-center">
                                        <div class="power-ic">
<!--                                            <i class="fa fa-spinner" aria-hidden="true"></i>-->
                                        </div>
                                        <div class="ml-2">
                                            <span>
                                                progress <small class="text-primary">{{Math.floor(getPlanPercentageDiff(deposit.data))}}%</small>
                                            </span>
                                            <div class="progress" style="height: 20px">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{width: getPlanPercentageDiff(deposit.data)+'%'}">
                                                    {{Math.floor(getPlanPercentageDiff(deposit.data))}}%
                                                </div>
                                            </div>
<!--                                            <h5 class="mb-0 pt-1 font-w500 text-black">-->
<!--                                            </h5>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-2 my-2 col-lg-6 col-sm-6">
                                    <div class="d-flex project-status align-items-center">
                                        <span class="btn bgl-warning text-warning status-btn mr-3" v-if="deposit.data.status === 'PENDING'">
                                            {{ deposit.data.status }}
                                        </span>
                                        <span class="btn bgl-info text-info status-btn mr-3" v-else-if="deposit.data.status === 'IN-PROGRESS'">
                                            {{ deposit.data.status }}
                                        </span>
                                        <span class="btn bgl-danger text-danger status-btn mr-3" v-else-if="deposit.data.status === 'DECLINED'">
                                            {{ deposit.data.status }}
                                        </span>
                                        <span class="btn bgl-success text-success status-btn mr-3" v-else>
                                            {{ deposit.data.status }}
                                        </span>
                                        <div class="dropdown">
                                            <a href="javascript:void(0);" data-toggle="dropdown" aria-expanded="false">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item" href="javascript:void(0);" @click="active_deposit=deposit" data-toggle="modal" data-target="#viewDeposit">
                                                    <i class="fa fa-eye"></i> View
                                                </a>
                                                <template v-if="deposit.data.status==='PENDING'">
                                                    <a class="dropdown-item text-success" href="javascript:void(0);" @click="confirmDep(deposit)">
                                                        <i class="fa fa-check-circle"></i> Confirm
                                                    </a>
                                                    <a class="dropdown-item text-danger" href="javascript:void(0);" @click="updateDepositStatus(deposit, 'DECLINED')">
                                                        <i class="fa fa-ban"></i> Decline
                                                    </a>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="card" style="margin-bottom: 200px" v-else>
                        <div class="card-body text-center">
                            <h4 class="text-info">Empty deposit history</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <view-deposit :deposit="active_deposit"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import methodsMixin from "../../../utils/methodsMixin";
import ViewDeposit from "./ViewDeposit";

export default {
    name: "DepositList",
    data(){
        return {
            active_deposit: {id: '', data: {}}
        }
    },
    mixins: [methodsMixin],
    computed: {
        ...mapGetters('deposit', {
            deposits: 'getAllDeposits'
        }),
        inProgress(){
            return this.deposits.filter(deposit=>deposit.data.status==='IN-PROGRESS')
        },
        pending(){
            return this.deposits.filter(deposit=>deposit.data.status==='PENDING')
        },
        filled(){
            return this.deposits.filter(deposit=>deposit.data.status==='FILLED')
        },
    },
    components: {
      ViewDeposit
    },
    methods: {
        async confirmDep(deposit){
            const status = confirm("Continue? This action is irreversible");
            if(status){
                const response = await this.$store.dispatch('deposit/confirmDeposit', deposit);
                if(response.status){
                    return toastr.success("Deposit confirmed");
                }else{
                    return toastr.error(response.message);
                }
            }
        },
        async updateDepositStatus(deposit, status){
            if(confirm("Continue? This action is irreversible")){
                deposit.data.status = status;
                const response = await this.$store.dispatch('deposit/updateDeposit', deposit);
                if(response.status){
                    return toastr.success("Deposit declined");
                }else{
                    return toastr.error(response.message);
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('deposit/getAllDeposits')
    }
}
</script>

<style scoped>

</style>