<template>
    <div class="modal fade" id="viewDeposit">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">#TE-{{deposit.id.substr(0,10)}}</h5>
                    <button type="button" class="close" data-dismiss="modal"><span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card contact-bx item-content">
                        <div class="card-header border-0">

                        </div>
                        <div class="card-body user-profile">
                            <div class="image-bx">
                                <span class="icon-placeholder bgl-success rounded-circle text-success" v-if="deposit.data.plan">
                                    {{deposit.data.plan.substr(0,2)}}
                                </span>
                            </div>
                            <div class="media-body user-meta-info">
                                <h6 class="fs-20 font-w500 my-1">
                                    <a href="javascript:void(0)" class="text-black user-name" :data-name="deposit.data.plan">
                                        {{deposit.data.plan}}
                                    </a>
                                </h6>
                                <p class="fs-14 mb-3 user-work" :data-occupation="deposit.data.package">
                                    {{deposit.data.package}}
                                </p>
                                <div class="row justify-content-center">
                                    <p class="fs-14 mb-3 user-work">
                                        <span class="btn bgl-warning text-warning status-btn mr-3" v-if="deposit.data.status === 'PENDING'">
                                                    {{ deposit.data.status }}
                                                </span>
                                        <span class="btn bgl-info text-info status-btn mr-3" v-else-if="deposit.data.status === 'IN-PROGRESS'">
                                                {{ deposit.data.status }}
                                            </span>
                                        <span class="btn bgl-danger text-danger status-btn mr-3" v-else-if="deposit.data.status === 'DECLINED'">
                                                {{ deposit.data.status }}
                                            </span>
                                        <span class="btn bgl-success text-success status-btn mr-3" v-else>
                                                {{ deposit.data.status }}
                                            </span>
                                    </p>
                                </div>
                                <p class="fs-14 mb-3 user-work alert alert-outline-info" v-if="deposit.data.status === 'IN-PROGRESS'">
                                    Earnings made from this deposit can only be actualized when it's position have been filled
                                </p>

                            </div>

                            <div class="container-fluid px-0">
                                <div class="row text-left">
                                    <template v-if="user.id">
                                        <div class="col-12">
                                            <h6>User</h6>
                                        </div>
                                        <div class="col-12 mb-3">{{user.data.name}}</div>
                                        <div class="col-12">
                                            <h6>User Email</h6>
                                        </div>
                                        <div class="col-12 mb-3">{{user.data.email}}</div>
                                    </template>
                                    <div class="col-12">
                                        <h6>Amount deposited</h6>
                                    </div>
                                    <div class="col-12 mb-3" v-if="deposit.data.amountDeposited">${{deposit.data.amountDeposited.toLocaleString()}}</div>

                                    <div class="col-12">
                                        <h6>Amount earned</h6>
                                    </div>
                                    <div class="col-12 mb-3" v-if="deposit.data.amountEarned">${{deposit.data.amountEarned.toLocaleString()}}</div>

                                    <div class="col-12">
                                        <h6>Debit source</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{deposit.data.paidThrough}}</div>

                                    <div class="col-12">
                                        <h6>Credited to</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{deposit.data.walletAddress||'Not Assigned'}}</div>

                                    <div class="col-12">
                                        <h6>Last updated</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{getReadableDate(deposit.data.updatedAt)}}</div>

                                    <div class="col-12">
                                        <h6>Created at</h6>
                                    </div>
                                    <div class="col-12">{{getReadableDate(deposit.data.createdAt)}}</div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-dismiss="modal">Close</button>
<!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../../utils/methodsMixin";

export default {
    name: "ViewDeposit",
    data(){
      return {
          user: {id: '', data: {}}
      }
    },
    props: ['deposit'],
    mixins: [methodsMixin],
    methods: {
        async fetchMeta(){
            const response = await this.$store.dispatch('user/getUser', this.deposit.data.user);
            if(response.status){
                this.user = response.data;
            }else{
                console.log(response.message)
            }
        }
    },
    mounted() {
        const modalElem = $('#viewDeposit');
        modalElem.on('show.bs.modal', ()=>{
            this.fetchMeta();
        });
        modalElem.on('hide.bs.modal', ()=>{
            this.user = {id: '', data: {}};
        })
    }
}
</script>

<style scoped>

</style>